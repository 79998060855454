import React from "react"

import Layout from "../components/Layout"
import Videos from "../components/Videos"
import HomeSections from "../components/HomeSections"

export default () => (
  <Layout>
    <HomeSections title="404" subtitle="Página no encontrada" isLight>
      <Videos id="videos" />
    </HomeSections>
  </Layout>
)
